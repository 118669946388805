.react-datepicker__input-container input {
  width: 100% !important;
  border: 1px solid rgba(0, 0, 0, 0.23) !important;
  padding: 10px;
  border-radius: 4px;

  /* bordercolor: #8898aa !important; */
}

.react-datepicker__input-container {
  background: white;
}

.input-group.input-group-alternative {
  background: white !important;
}

.rounded-circle {
  height: 36px !important;
}
