body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
body{
  overflow-y: scroll !important;
}
.form-control form-check-input{
  size: 0.5rem;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.modal-height {
  max-height: calc(100vh - 130px);
  overflow: auto;
}

.centered {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transform: -webkit-translate(-50%, -50%);
  transform: -moz-translate(-50%, -50%);
  transform: -ms-translate(-50%, -50%);
}

.mt-165 {
  margin-top: -165px !important;
}

.bt-table {
  width: 100%;
  overflow: auto;
}

.pagination-btn {
  min-width: 75px;
}

.pointer {
  cursor: pointer;
}

.loading-dialog {
  z-index: 99;
}

.full-page-dialog {
  margin: 0 !important;
  max-width: 100% !important;
}

.full-page-dialog > div {
  width: 100vw !important;
  min-width: 100vw !important;
  height: 100vh !important;
  min-height: 100vh !important;
}

.modal-open .modal {
  width: 100vw;
}

.employee-dialog-body {
  height: calc(100vh - 70px);
  overflow: auto;
}

.h-52px {
  height: 52px;
}
